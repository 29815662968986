import soundPlayer from '../../../../soundPlayer';
import './index.scss'

type Props = {
  photoLink: string;
  name: string; 
  work: {
    exp: string;
    rank: string;
    about: string;
  }
  onClick?: () => void 
}

const CouchBlock = (props:Props) => {
  const { name, photoLink,work, onClick = () => {} } = props;

  return (
    <div className='couch-block' onClick={()=>{
      soundPlayer.mediumWhooshSound()
      onClick()
    }} onMouseEnter={()=>{
      soundPlayer.fastWhooshSound(0.1)
    }}>
        <div className='couch-block__background'/>
        <div className='couch-block__noise'/>
        <img
            src={photoLink} 
            alt={`фото тренера ${name}`}
            id={`couch-block__photo-${name}`}
            className='couch-block__photo'
        />
        <div className='couch-block__name'>
            <span>{name.split(' ')[0]}</span>
        </div>
        <div 
          id={`couch-block__short-info-${name}`}
          className='couch-block__short-info'
        >
          <span>СТАЖ - {work.exp}</span>
          <span>{work.rank === 'Мастер спорта' ? 'МС' : work.rank}</span>
        </div>
    </div>
  )
}

export default CouchBlock;