import { Link } from 'react-router-dom';
import './index.scss'

const Footer = () => {
  return (
    <footer className="footer">
      <div className='footer__container'>
        {/* <span className='footer__title'>TENNIS КУЧИНО</span> */}
        <div className='footer__links'>
          <Link to={'/'}>
            <span className='footer__link'>Главная</span>
          </Link>
          <Link to={'/about-us'}>
            <span className='footer__link'>О нас</span>
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer;