import { Howl } from 'howler';

const soundPlayer = {
  preloadedSounds: new Howl({
    src: ['./assets/sounds/sounds.mp3'],
    sprite: {
      click: [5, 150],
      fastWhoosh: [420, 850],
      mediumWhoosh: [1100, 1180],
      slowWhoosh: [2325, 1180],
      waterdrop: [3585, 50],
    },
  }),

  playSound: function(soundPath: string, volume = 1, delay = 0): void {
    if (window.localStorage.getItem('isSoundDisabled') === 'true') return;
    this.preloadedSounds.volume(volume)

    const playDelayed = () => {
      setTimeout(() => {
        this.preloadedSounds.play(soundPath)
      }, delay);
    };

    if (delay === 0) {
      this.preloadedSounds.play(soundPath)
    } else {
      playDelayed();
    }
  },

  waterSound: function(): void {
    this.playSound('waterdrop', 0.5);
  },

  fastWhooshSound: function(volume?:number): void {
    this.playSound('fastWhoosh', volume || 0.3);
  },

  mediumWhooshSound: function(): void {
    this.playSound('mediumWhoosh', 0.5);
  },

  slowWhooshSound: function(): void {
    this.playSound('slowWhoosh', 0.5, 1000);
  },

  clickSound: function(): void {
    this.playSound('click', 0.3);
  },

  playBackgroundMusic: function(): void {
    if (window.localStorage.getItem('isSoundDisabled') === 'true') return;

    const sound = document.querySelector("audio") as HTMLAudioElement;
    if (!sound) return;

    sound.loop = true;
    sound.volume = 0.2;
    sound.play().catch((error) => console.log('Error playing background music:', error));
  },

  stopBackgroundMusic: function(): void {
    const sound = document.querySelector("audio") as HTMLAudioElement;
    if (!sound) return;

    sound.pause();
  },
};

export default soundPlayer;