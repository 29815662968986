import './index.scss'

import { MainScreenRename, Table } from "./components"

const Reservations = () => {
  return (
    <div className='body'>
      <MainScreenRename />
      <Table />
    </div>
  )
}

export default Reservations