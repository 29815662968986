import CrossIcon from '../../../../svg/CrossIcon'
import './index.scss'

type Coach = {
  photoLink: string;
  name: string; 
  work: {
    exp: string;
    rank: string;
    about: string;
  }
}

type Props = {
  isOpened: boolean;
  setIsOpened: (state: boolean) => void;
  coach: Coach
}

const ModalCouchBlock = (props:Props) => {
  const { isOpened, setIsOpened, coach } = props;

  return (
    <div className={`modal-couch ${isOpened ? 'opened' : ''}`}>
      <div className='modal-container'>
        {/* Кнопка закрытия */}
        <button 
          onClick={()=>setIsOpened(false)} 
          className='modal-couch__close-button'
        >
          <CrossIcon/>
        </button>

        <div className='modal-couch__background'/>
        {/* Блок с фото */}
        <img 
          className='modal-couch__photo'
          src={coach.photoLink}
          alt="Фотография тренера"
        />

        {/* Блок с информацией */}
        <div className='modal-couch__info'>
          <p className='modal-couch__info__title'>{coach.name}</p>
          <div className='modal-couch__info__work'>
            <p className='modal-couch__info__work__point'>Стаж: {coach.work.exp}</p>
            <p className='modal-couch__info__work__point'>{coach.work.rank}</p>
          </div>
          <div className='modal-couch__info__about'>
            <p>{coach.work.about}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalCouchBlock
