import ArrowIcon from "../../../../svg/ArrowIcon";
import './index.scss'

type Props = {
  onClick: () => void;
  rotate: number;
}

const ArrowButton = (props:Props) => {
  const { onClick, rotate } = props;

  return (
    <button onClick={onClick} style={{rotate: `${rotate}deg`}} className="arrow-button">
      <ArrowIcon/>
    </button>
  )
}

export default ArrowButton