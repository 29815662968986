import './index.scss'

type Props = {
  color?: string
  text: string
  onClick: () => void
}

const LineButton = ({color = '#A4F044', text, onClick}: Props) => {
  return (
    <button className="line-button" onClick={onClick}>
      <div className='line-button__transition-div'/>
      <div className='line-button__transition-div line-button__transition-div_black'/>
      <span>{text}</span>
    </button>
  )
}

export default LineButton