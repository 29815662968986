import { create } from 'zustand'

interface StoreState {
  isLoaderHidden: boolean
  hideLoader: () => void
  isMainAnimationStarted: boolean
  startMainAnimation: () => void
}

const useStore = create<StoreState>((set) => ({
  isLoaderHidden: false,
  hideLoader: () => set(() => ({ isLoaderHidden: true })),
  isMainAnimationStarted: false,
  startMainAnimation: () => set(() => ({ isMainAnimationStarted: true })),
}))

export default useStore