// All photos by Matt Perry https://citizenofnowhe.re
import { useEffect, cloneElement } from "react";
import { AnimatePresence } from "framer-motion";
import { useLocation, useRoutes } from "react-router-dom";
import { AboutUsPage, MainPage, ReservationsPage } from './pages';
import './App.css'
import soundPlayer from "./components/soundPlayer";

export default function App() {
  useEffect(()=>{
    window.addEventListener('visibilitychange',()=>{
      console.log('page leaved')
      soundPlayer.stopBackgroundMusic()
    })
  },[])
  const element = useRoutes([
    {
      path: "/",
      element: <MainPage/>,
    },
    {
      path: "/about-us",
      element: <AboutUsPage/>,
    },
    {
      path: "/reservations",
      element: <ReservationsPage/>,
    },
  ]);

  const location = useLocation();

  if (!element) return null;

  return (
    <AnimatePresence mode="wait">
      {cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  );
}
