import './index.scss'

type Props = {
  text: string
}

const FilledText = ({text}:Props) => {
  return (
    <span className='home-filled-text'>{text}</span>
  )
}

export default FilledText