import './index.scss'

const YaMap = () => {
    return (
        <div style={{position:'relative',overflow:'hidden', borderRadius:'10px', height: '100%'}}><a href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps" style={{color:'#eee',fontSize:'12px',position:'absolute',top:'0px'}}>Москва</a><a href="https://yandex.ru/maps/10716/balashiha/house/yuzhnaya_ulitsa_17/Z04YfwNmT0wAQFtvfXt1cntmYQ==/?ll=37.946789%2C55.743671&utm_medium=mapframe&utm_source=maps&z=19.48" style={{color:'#eee',fontSize:'12px',position:'absolute',top:'14px'}}>Южная улица, 17 — Яндекс Карты</a>
            <iframe 
                title="map"
                className='ya-map__frame'
                src="https://yandex.ru/map-widget/v1/?ll=37.946789%2C55.743671&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjcwMjg3MBKBAdCg0L7RgdGB0LjRjywg0JzQvtGB0LrQvtCy0YHQutCw0Y8g0L7QsdC70LDRgdGC0YwsINCR0LDQu9Cw0YjQuNGF0LAsINC80LjQutGA0L7RgNCw0LnQvtC9INCa0YPRh9C40L3Qviwg0K7QttC90LDRjyDRg9C70LjRhtCwLCAxNyIKDWzJF0IVmvleQg%2C%2C&z=19.48" width="560" height="400" 
                frameBorder="1" 
                allowFullScreen={true} 
                style={{position:'relative', borderRadius:'10px',border:'none'}}
            />
        </div>
    )
}

const Contacts = () => {
    return (
        <div className='contacts container'>
            {window.innerWidth > 500 && (<img 
                className='contacts__image' 
                src="./images/contacts.png" 
                alt="фон контактов" 
            />)}
            <div className='contacts__content'>
                <div className='contacts__content__left'>
                    <div className='contacts__texts'>
                        <h2 className='contacts__title'>Есть вопросы?</h2>
                        <h3 className='contacts__subtitle'>Свяжитесь с нами</h3>
                    </div>
                    <div className='contacts__links'>
                        <a href="tel:+79670715053">+7 (967) 071-50-53</a>
                        <a href="mailto:tennis-kuchino@mail.ru">
                            tennis-kuchino@mail.ru
                        </a>
                    </div>
                </div>
                <div className='contacts__content__right'>
                    <YaMap />
                </div>
            </div>
        </div>
    )
}

export default Contacts