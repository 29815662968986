import Header from '../../Header';
import MainAboutUs from './AboutUs';
import Contacts from './Contacts';
import Couches from './Couches';
import MainFaq from './Faq';
import MainScreen from './MainScreen';

const Main = () => {
  return (
    <>
      <Header/>
      <MainScreen />
      <MainAboutUs />
      <Couches/>
      <MainFaq />
      <Contacts />
    </>
  )
}

export default Main