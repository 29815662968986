import { FaqItem } from '../components'
import './index.scss'

const MainFaq = () => {
    return (
        <>  
        {/* <div className="divider container"/> */}
        <div className='main-faq'>
            <h3 className='main-faq__title'>FAQ</h3>
            <div className='main-faq__container small-container'>
                <FaqItem title="Сколько стоит аренда корта?" text='Стоимость аренды необходимо уточнять по телефону'/>
                <FaqItem title="Где вы находитесь?" text='Мы находимся по адресу ул.Южная, д.17'/>
                <FaqItem title="Можно ли арендовать оборудование?" text='Конечно, у нас большой выбор снаряжения'/>
            </div>
        </div>
        </>
    )
}

export default MainFaq