import './index.scss'

type Props = {
  color?: string
  text: string
  onClick: () => void
}

const FilledButton = ({color = '#A4F044', text, onClick}: Props) => {
  return (
    <button className="filled-button" onClick={onClick}>
      <div className='filled-button__transition-div'/>
      <span>{text}</span>
    </button>
  )
}

export default FilledButton