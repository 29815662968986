import './index.scss'

const MainScreenRename = () => {
  return (
    <div className='main-screen'>
      <img 
        className='image'
        src="https://images.unsplash.com/photo-1448743133657-f67644da3008?q=80&w=2128&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" 
        alt="Изображение теннисного корта"
      />
      <div className='text-block'>
        <span className='text-block__title'>Хотите сыграть?</span>
        <span className='text-block__subtitle'>Выберите подходящее время</span>
        <button className='text-block__button'>Забронировать</button>
      </div>
    </div>
  )
}

export default MainScreenRename