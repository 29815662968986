import { useCallback, useEffect, useMemo } from 'react'
import { gsap } from "gsap"
import './index.scss'
import { FilledButton, LineButton } from '../Buttons'
import useStore from '../../store'
import soundPlayer from '../soundPlayer'

const MainLoader = () => {
  const { hideLoader, startMainAnimation } = useStore()
  const isMobile = useMemo(() => window.innerWidth < 768, [])

  useEffect(() => {
    document.body.classList.add('no-scroll')
    gsap.to('.loader__hide-title',{
      top: isMobile ? '10%' : '25%',
      duration: 5,
      onComplete: () => {
        const element = document.querySelector('.loader__hide-title')
        element && element.classList.add('loader__display-none')
      }
    })
    gsap.to('.loader__title',{
      translateY: isMobile ? '-65%' : '-55%',
      delay:1,
      duration: 1,
      ease: 'power1.inOut'
    })
    gsap.to('.loader__hide',{
      opacity:1,
      pointerEvents: 'all',
      delay:3,
      duration: 1
    })
    gsap.to('.loader__bottom-button',{
      opacity:1,
      delay:3,
      pointerEvents: 'all',
      duration: 1
    })
  },[isMobile])

  const baseOnClick = useCallback(() => {
    document.body.classList.remove('no-scroll')
    gsap.to('.loader',{
      translateY: '-100%',
      duration: 2,
      ease: 'power3.in',
      onComplete: () => {
        const element = document.querySelector('.loader')
        element?.classList.add('loader__display-none')
        hideLoader()
      }
    })
    startMainAnimation()
  },[hideLoader, startMainAnimation])
 
  return (
    <div className="loader">
      <div className='loader__hide-title'/>
      <span className='loader__title'>ТЕННИС КУЧИНО</span>
      <div className='loader__hide'>
        <span className='loader__subtitle'>Корт с удобствами рядом с Вами</span>
        <FilledButton text='Войти' onClick={()=>{
          window.localStorage.setItem('isSoundDisabled', 'false')
          soundPlayer.slowWhooshSound();
          soundPlayer.playBackgroundMusic()
          baseOnClick()
        }}/>
      </div>
      <div className='loader__bottom-button'>
        <LineButton text='Войти без звука' onClick={()=>{
          window.localStorage.setItem('isSoundDisabled', 'true')
          baseOnClick()
        }}/>
      </div>
    </div>
  )
}

export default MainLoader