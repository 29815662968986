import './index.scss'
import ScrollDown from '../../../../ScrollDown'
import { useEffect } from 'react'
import { gsap } from 'gsap'

const InfoModal = ({onComplete}:any) => {
  useEffect(()=>{
    gsap.to('.info-modal',{
      opacity: 0,
      delay: 3,
      onComplete:()=>{
        const element = document.getElementById('info-modal')
        document.body.classList.remove('no-events')
        if(!element) return
        element.style.display = 'none'
        onComplete()
      }
    })
  },[onComplete])
  return (
    <div className="info-modal" id="info-modal">
      <div className='info-modal__container'>
        <ScrollDown/>
      </div>
    </div>
  )
}

export default InfoModal