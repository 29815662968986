import './index.scss'
import { ArrowButton, CouchBlock, ModalCouchBlock } from '../components'
import { useCallback, useEffect, useState } from 'react'

//@ts-ignore
import DinaraImage from '../../../../assets/couches/dinara.png' 
//@ts-ignore
import LizaImage from '../../../../assets/couches/liza.png' 
//@ts-ignore
import DenisImage from '../../../../assets/couches/denis.png' 
import soundPlayer from '../../../soundPlayer'

const preloadSrcList: string[] = [
    DinaraImage,
    LizaImage,
    DenisImage,
  ]
  
  function preloadImage (src: string) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = function() {
        resolve(img)
      }
      img.onerror = img.onabort = function() {
        reject(src)
      }
      img.src = src
    })
  }

const Couches = () => {
    const [assetsLoaded, setAssetsLoaded] = useState<boolean>(false)

    useEffect(() => {
        let isCancelled = false

        async function effect() {
        if (isCancelled) {
            return
        }

        const imagesPromiseList: Promise<any>[] = []
        for (const i of preloadSrcList) {
            imagesPromiseList.push(preloadImage(i))
        }
    
        await Promise.all(imagesPromiseList)

        if (isCancelled) {
            return
        }

        setAssetsLoaded(true)
        }

        effect()

        return () => {
        isCancelled = true
        }
    }, [])
    const isMobile = window.innerWidth <= 425

    const [modalOpened, setIsModalOpened] = useState(false)

    const [couchNumber,setCouchNumber] = useState(0)
    const couchesInfo = [
        {
            photoLink: DinaraImage,
            name: 'Динара Еналеева',
            work:{
                exp: '4 года',
                rank: 'Мастер спорта',
                about: 'Победитель и призер турниров РТТ, участник международных турниров серии ТЕ и ITF в одиночном и парном разрядах'
            }
        },
        {
            photoLink: LizaImage,
            name: 'Елизавета Дементьева',
            work:{
                exp: '4 года',
                rank: 'Мастер спорта',
                about: 'Победитель и призер  международных турниров серии ITF и ТЕ в одиночном и парном разрядах. Бывший член сборной России по теннису 2014-2021 гг.'
            }
        },
        {
            photoLink: DenisImage,
            name: 'Денис Заикин',
            work:{
                exp: '6 лет',
                rank: 'КМС',
                about: 'Победитель и призер турниров РТТ, участник международных турниров серии ТЕ в одиночном и парном разрядах'
            }
        },
    ]

    const handleCouchChange = useCallback((type:'forward' | 'backwards')=> {
        soundPlayer.clickSound()
        let nextCouchNumber = 0
        if(type === 'forward'){
            nextCouchNumber = couchNumber + 1 === couchesInfo.length ? 0 : couchNumber + 1
        }
        if(type === 'backwards'){
            nextCouchNumber = couchNumber - 1 < 0 ? couchesInfo.length - 1 : couchNumber - 1
        }
        setCouchNumber(nextCouchNumber)
    },[couchNumber, couchesInfo.length])

    return (
        <div className='couches'>
            <ModalCouchBlock 
                isOpened={modalOpened}
                setIsOpened={setIsModalOpened}
                coach={couchesInfo[couchNumber]}
            />
            <span className='couches__title'>НАШИ ТРЕНЕРЫ</span>
            <div className="couches__container">
                {!isMobile && couchesInfo.map((couch,index) => {
                    return <CouchBlock {...couch}  onClick={()=>{
                        setCouchNumber(index)
                        setIsModalOpened(true)
                    }}/>
                })}
                {isMobile && (
                <>  
                    <div className='arrow-buttons__container'>
                        <ArrowButton onClick={()=>handleCouchChange('backwards')} rotate={90}/>
                        <ArrowButton onClick={()=>handleCouchChange('forward')} rotate={-90}/>
                    </div>
                    <CouchBlock {...couchesInfo[couchNumber]} onClick={()=>setIsModalOpened(true)}/>
                </>
                )}
            </div>
        </div>
    )
}

export default Couches