import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { FilledText, OutlinedText } from '../components'
import './index.scss'
import {gsap} from 'gsap'
import { motion, useInView } from 'framer-motion';
import useMouse from '@react-hook/mouse-position'

const AnimatedText = ({transformX,text,delay}:any) => {
    return (
    <motion.div
        initial={{x: transformX}}
        whileInView={{x:-0}}
        // viewport={{once: true}}
        transition={{duration: 4,delay: delay}}
    >
        <OutlinedText text={text}/>
    </motion.div> 
    )
}


const MainAboutUs = () => {
    const ref = useRef(null)
    const isMobile = window.innerWidth < 768
    const isInView = useInView(ref,{
        margin: isMobile ? '-350px 0px 0px 0px' : '0px 0px 0px 0px'
    })
    console.log(isInView)
    const [cursorVariant, setCursorVariant] = useState("default");

    useEffect(()=>{
        if(isInView && isMobile){
            setCursorVariant("project")
        } 
        if(isMobile && !isInView){
            setCursorVariant("default")
        }
    },[isInView, isMobile])

    const navigate = useNavigate()

    const mouse = useMouse(ref, {
        enterDelay: 100,
        leaveDelay: 100
    });

    let mouseXPosition = 0;
    let mouseYPosition = 0;

    if (mouse.x !== null && mouse.clientX !== null) {
        mouseXPosition = mouse.clientX;
    }

    if (mouse.y !== null && mouse.clientY !== null) {
        mouseYPosition = mouse.clientY;
    }

    const variants = {
        default: {
        opacity: (isMobile && isInView) ? 1 : 0,
        height: 0,
        width: 0,
        fontSize: "16px",
        backgroundColor: "#1e91d6",
        x: mouseXPosition,
        y: mouseYPosition,
        transition: {
            type: "spring",
            mass: 0.6
        }
        },
        project: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        opacity: 1,
        backgroundColor: "#446ff0",
        borderRadius: '50%',
        color: "#dadada",
        height: 100,
        width: 100,
        fontSize: "14px",
        x: isMobile ? window.innerWidth * 0.7 : (mouseXPosition - 32),
        y: isMobile ? window.innerHeight * 0.83 : (mouseYPosition - 32)
        },
    };

    const spring = {
        type: "spring",
        stiffness: 500,
        damping: 28
    };

    function projectEnter() {
        setCursorVariant("project");
    }

    function projectLeave() {
        setCursorVariant("default");
    }
        
    return (
        <div 
            className='main-about-page' 
            ref={ref} 
            onMouseEnter={projectEnter}
            onMouseLeave={projectLeave}
            onClick={()=> navigate('/about-us')}
        >
            <motion.div
            variants={variants}
            className="circle"
            animate={cursorVariant}
            transition={spring}
            >
                <span className="cursorText">В ГАЛЕРЕЮ</span>
            </motion.div>
            <AnimatedText transformX={isMobile ? -300 : 50} text={'качественно'} delay={isMobile ? 0 : 0}/>
            <AnimatedText transformX={isMobile ? -300 : -100} text={'эффективно'} delay={isMobile ? 2 : 0}/>
            {/* <OutlinedText text='качественно'/>
            <OutlinedText text='эффективно'/> */}
            <FilledText text='у нас'/>
            <AnimatedText transformX={isMobile ? -300 : 100} text={'безопасно'} delay={isMobile ? 4 : 0}/>
            <AnimatedText transformX={isMobile ? -300 : -50} text={'комфортно'} delay={isMobile ? 6 : 0}/>
            {/* <OutlinedText text='безопасно'/>
            <OutlinedText text='комфортно'/> */}
            <motion.img 
                className='main-about-page__tennis-racket' 
                src='/images/tennis-racket.png' 
                alt="Теннисная ракетка"
            />
        </div>
    )
}

export default MainAboutUs