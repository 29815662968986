import { useCallback, useState } from 'react';
import ArrowIcon from '../../../../svg/ArrowIcon';
import './index.scss'
import soundPlayer from '../../../../soundPlayer';

type FaqItemProps = {
  title: string;
  text: string;
}

const FaqItem = (props:FaqItemProps) => {
  const { title, text } = props;
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = useCallback(()=>{
    soundPlayer.clickSound()
    setIsOpen(prev => !prev)
  },[])

  return(
    <div className={`faq-item ${isOpen ? 'opened' : ''}`}>
      <div className='faq-item__container' onClick={toggleOpen}>
        <div className='faq-item__question'>
          <span className='faq-item__title'>{title}</span>
          <div className={`faq-item__arrow ${isOpen ? 'opened' : ''}`}>
            <ArrowIcon/>
          </div>
        </div>
        <div className='faq-item__answer'>
          <p className='faq-item__subtitle'>{text}</p>
        </div>
      </div>
    </div>
  )
}

export default FaqItem