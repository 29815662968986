import { AnimatedContainer, HomePage, Loader, Footer } from "../components";
import useStore from "../store";

const MainPage = () => {
  const { isLoaderHidden } = useStore()
  return (
    <AnimatedContainer className="App">
      {!isLoaderHidden && <Loader/>}
      <HomePage/>
      <Footer />
    </AnimatedContainer>
  );
}

export default MainPage;
