import { Link } from "react-router-dom";
import BurgerMenu from "./BurgerMenu";
import './index.scss'

const Header = () => {
  return (
    <header className="header">
      <div className="header__container">
        <Link to={'/'}>
          <span className="header__container__title">
              TENNIS 
              <span className="header__container__title__second-part">КУЧИНО</span>
          </span>
        </Link>
        <BurgerMenu/>
      </div>
    </header>
  )
}

export default Header;