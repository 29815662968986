import { AboutUs, AnimatedContainer, Header } from "../components";

const AboutUsPage = () => {
  return (
    <AnimatedContainer
      className="App"
    >
      <Header/>
      <AboutUs />
    </AnimatedContainer>
  );
}

export default AboutUsPage;
