import { useAnimationControls, motion } from 'framer-motion'
import './index.scss'
import { useCallback, useEffect } from 'react'
import useStore from '../../../../store'
import { gsap } from 'gsap'
import ArrowIcon from '../../../svg/ArrowIcon'

const MainScreen = () => {
    const { isMainAnimationStarted } = useStore()
    const controls = useAnimationControls()
  
    useEffect(() => {
        if(isMainAnimationStarted) {
            controls.start({ 
                opacity: 1, 
                transition:{ duration:2, delay:1 } 
            })
            gsap.to('.main-screen-page__text-block__title',{
                y: 0,
                opacity: 1,
                duration: 2,
                ease: 'power1.inOut',
                delay: 2,
            })
            gsap.to('.main-screen-page__button',{
                opacity: 1,
                duration: 1,
                ease: 'power1.inOut',
                delay: 2,
            })
        }
    }, [controls, isMainAnimationStarted])

    const handleButtonClick = useCallback(() => {
        window.scrollTo({top: window.innerHeight, behavior: 'smooth'})
    },[])

    return (
        <div className='main-screen-page'>
            <motion.img 
                initial={{ opacity:0 }}
                animate={controls}
                className='main-screen-page__image'
                src="/images/main-screen02.webp" 
                alt="Изображение теннисного корта"
            />
            <button 
                className='main-screen-page__button'
                onClick={handleButtonClick}
            >
                <ArrowIcon/>
            </button>
            <div className='main-screen-page__text-block'>
                <span className='main-screen-page__text-block__title'>
                    <span className='bold'>СПОРТ</span> 
                    ВСТРЕЧАЕТ 
                    <span className='bold'>КОМФОРТ</span>
                </span>
            </div>
        </div>
    )
}

export default MainScreen