type CrossIconProps = {
  id?: string
}

const CrossIcon = ({id = ""}: CrossIconProps) => {
  return (
    <svg fill="#ffffff" width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" id={id}><path d="M697.4 759.2l61.8-61.8L573.8 512l185.4-185.4-61.8-61.8L512 450.2 326.6 264.8l-61.8 61.8L450.2 512 264.8 697.4l61.8 61.8L512 573.8z"/></svg>
  )
}

export default CrossIcon;