import { useCallback, useMemo, useState } from 'react';
import './index.scss'
import { Link, useLocation } from 'react-router-dom';
import BurgerIcon from '../../svg/BurgerIcon';
import CrossIcon from '../../svg/CrossIcon';

import { gsap } from 'gsap'
import soundPlayer from '../../soundPlayer';

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const menuData = useMemo(()=> ([
      {link:'/', name: 'Главная', index:0},
      {link:'/about-us', name: 'О нас', index: 1},
    ]
  ),[])

  const handleMenuToggle = useCallback(() => {
    isOpen ? soundPlayer.mediumWhooshSound() : soundPlayer.fastWhooshSound()
    gsap.to('.burger__button',{
      background: isOpen ? 'rgba(255, 255, 255, 0.623)' : 'rgba(0, 0, 0, 0.80)',
      duration:0.3
    })
    gsap.to('#burgericon',{
      left: isOpen ? '50%' : '130%',
      duration:0.3
    })
    gsap.to('#crossicon',{
      left: isOpen ? '-20%' : '50%',
      duration:0.3
    })
    gsap.to('.burger__menu',{
      translateX: isOpen ? '100%' : '0%',
      ease: 'power2.inOut',
      duration: 1
    })
    setIsOpen(prev => !prev)
  },[isOpen])

  const hoverSound = useCallback(()=>{
    !isOpen && soundPlayer.waterSound()
  },[isOpen])

  return (
    <div className='burger'>
      <button className={`burger__button ${isOpen && 'opened'}`} onClick={handleMenuToggle} onMouseEnter={hoverSound}>
        <BurgerIcon />
        <CrossIcon id="crossicon"/>
      </button>
      <div className="burger__menu">
        <ul className='burger__menu__list'>
          {menuData.map((item)=>(
            <Link to={item.link} onClick={handleMenuToggle} key={item.index}>
              <li className={`burger__menu__list-item ${location.pathname === item.link && 'burger-link-active'}`}>{item.name}</li>
            </Link>
          ))}
        </ul>
        <div className='burger__footer'>
          <a href="mailto:tennis-kuchino@mail.ru">tennis-kuchino@mail.ru</a>
          <a href="tel:+79670715053">+7 (967) 071-50-53</a>
        </div>
      </div>
    </div>
  )
}

export default BurgerMenu;