import './index.scss'

type TableCellProps = {
  text: string
}

const TableCell = (props: TableCellProps) => {
  const { text } = props;

  return (
    <div className='cell'>
      {text}
    </div>
  )
}

export default TableCell