import { Reservations } from "../components"

const ReservationsPage = () => {
  return (
    <div>
      <Reservations/>
    </div>
  )
}

export default ReservationsPage