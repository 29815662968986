import { motion } from 'framer-motion'
import React from 'react'

type Props = {
  className?: string
  children: React.ReactNode
}

const AnimatedContainer = ({children, className = ""}:Props) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{opacity: 0 }}
      transition={{ duration: 1 }}
      className={className}
    >
      {children}
    </motion.div>
  )
}

export default AnimatedContainer