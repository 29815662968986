//@ts-nocheck
import { useMemo, useState, useEffect, useCallback } from 'react'
import { gsap } from 'gsap'
import './index.scss';

import TennisCourt from '../../../../../assets/images/tennis-court.png'
import SportInventory from '../../../../../assets/images/sport-inventory.png'
import ChillZone from '../../../../../assets/images/chill-zone.png'
import Gym from '../../../../../assets/images/gym.png'
import ChangeRoom from '../../../../../assets/images/changeroom.png'

type DataObject = {
  img: string;
  title: string;
  subtitle: string;
}

type Props = {
  data: DataObject[],
  dataNumber: number | null
}

// Компонент , рассчитанный на одну фишку из нескольких на странице "О нас"
const PointScreen = (props: Props) => {
  const { data, dataNumber } = props;
  const [ localData, setLocalData ] = useState({...data[0]})
  const [imageKey, setImageKey] = useState(0);
  const { img, title, subtitle } = localData
  const animationDuration = 1.5;

  const transformedText = useMemo(() => {
    const parts = title.split(/({[^}]+})/);
    console.log(parts,title)
    return parts.filter(part => part).map((part, index) => {
      if (part.startsWith('{') && part.endsWith('}')) {
        // Если часть текста заключена в фигурные скобки, создаем span с классом bold
        return (
          <span key={index} className="point-screen__text_title bold">
            {part.substring(1, part.length - 1)}
          </span>
        );
      } else {
        // В противном случае, создаем span с классом normal
        return (
          <span key={index} className="point-screen__text_title">
            {part}
          </span>
        );
      }
    });
  },[title])

  const animationStart = useCallback(async () => {
    const timeline1 = gsap.timeline({
      onComplete: () => {
        console.log(dataNumber, data[dataNumber || 0])
        dataNumber !== null && setLocalData(data[dataNumber])
        setImageKey(dataNumber)
        setTimeout(()=>{
          animationEnd()
        },300)
      }
    });

    timeline1
      .to('.point-screen__text_title', {
        translateY: '100%',
        duration: animationDuration,
      })
      .to('.point-screen__text_subtitle', {
        translateY: '100%',
        duration: animationDuration,
      }, `-=${animationDuration}`) // Start 2 seconds before the end of the previous animation
      .to('.point-screen', {
        opacity: 0,
        duration: animationDuration,
      }, `-=${animationDuration}`); // Start 2 seconds before the end of the previous animation
  },[data, dataNumber])

  const animationEnd = async() => {
    gsap.to('.point-screen__text_title',{
      translateY: '0px',
      duration: animationDuration,
      delay: 0.1,
    })
    gsap.to('.point-screen__text_subtitle',{
      translateY: '0px',
      duration: animationDuration,
      delay: 0.1,
    })
    gsap.to('.point-screen',{
      opacity: 1,
      duration: animationDuration,
      delay: 0.1,
    })
  }

  useEffect(()=>{
    if(dataNumber !== null){
      animationStart()
    }
  }, [animationStart, dataNumber]) 

  const imagesData = {
    'tennis-court': TennisCourt,
    'sport-inventory': SportInventory,
    'chill-zone': ChillZone,
    'gym': Gym,
    'change-room': ChangeRoom
  }
  console.log(img)
  return (
    <div className="point-screen">
      <img 
        key={imageKey}
        className="point-screen__image"
        src={imagesData[img]} 
        alt={`Изображение страницы ${title}`} 
      />
      <div className="point-screen__text">
        <div className='point-screen__text-overflow'>
          <span className="point-screen__text_title">{transformedText}</span>
        </div>
        <div className='point-screen__text-overflow'>
          <p className="point-screen__text_subtitle">{subtitle}</p>
        </div>
      </div>
      {/* <div className='point-screen__scroll'>
        <p className='point-screen__scroll_count'>{(dataNumber || 0) + 1} / {data.length}</p>
        <p>СКРОЛЛ ВНИЗ</p>
      </div> */}
    </div>
  )
}

export default PointScreen