import { useState, useEffect, useCallback } from 'react'

import './index.scss'

import { PointScreen, InfoModal } from "./components"

const AboutUs = () => {
  const [dataNumber, setDataNumber] = useState<null | number>(null)
  const [loaderHidden, setLoaderHidden] = useState(false)

  const data = [
    {
      img: 'tennis-court',
      title: '{ХАРД} ПОКРЫТИЕ',
      subtitle: 'Три слоя резины для безопасности Ваших суставов'
    },
    {
      img: 'sport-inventory',
      title: 'СПОРТ {ИНВЕНТАРЬ}',
      subtitle: 'Фишки, конусы, лесенка, степ, две большие корзины с мячами'
    },
    {
      img: 'chill-zone',
      title: '{ЗОНА} ОТДЫХА',
      subtitle: 'Удобное место с диванами для отдыха после тренировки'
    },
    {
      img: 'gym',
      title: 'СПОРТ {ЗАЛ}',
      subtitle: 'Две беговые дорожки, велосипеды и эллипсоид, шведская стенка и не только'
    },
    {
      img: 'change-room',
      title: 'РАЗДЕВАЛКИ',
      subtitle: 'Мужская и женская'
    },
  ]

  function preventScroll(e: any){
    e.preventDefault();
    e.stopPropagation();

    return false;
  }

  const handleScroll = useCallback((event:any) => {
    console.log(event)
    const direction = event.deltaY > 0 ? 'bottom' : 'top'
    if (direction === 'bottom') {
      setDataNumber((prev) => {
        if(prev == null) return 1
        return prev + 1 === data.length ? 0 : prev + 1
      });
    } else {
      setDataNumber((prev) => {
        if(prev == null) return data.length - 1
        return prev - 1 < 0 ? data.length - 1 : prev - 1
      });
    }
  }, [data.length]);

  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      document.body.addEventListener('wheel', preventScroll)
      func(...args);
      timeoutId = setTimeout(() => {
        document.body.removeEventListener('wheel', preventScroll)
      }, delay);
    };
  };

  const handleWheel = debounce(handleScroll, 2000); // Adjust the delay as needed
  useEffect(() => {
    if(!loaderHidden) return
    window.addEventListener('wheel', handleWheel);

    // For mobile devices
    window.addEventListener('touchstart', handleWheel);
    window.addEventListener('touchend', handleWheel);

    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchstart', handleWheel);
      window.removeEventListener('touchend', handleWheel);
    };
  }, [handleScroll, handleWheel, loaderHidden]);

  console.log(dataNumber)
  return (
    <div className='about-us'>
      <InfoModal 
        onComplete={()=>setLoaderHidden(true)}
      />
      <PointScreen
        data={data}
        dataNumber={dataNumber}
       />
    </div>
  )
}

export default AboutUs